
import Vue from "vue";
import ProductFilterSimple from "@/components/ProductFilterSimple.vue";
import * as api from "@/api";
import ProductSortSelector from "@/components/ProductSortSelector.vue";
import ProductStatus from "@/components/ProductStatus.vue";

export default Vue.extend({
  name: "ProductRecycleBin",
  components: {
    ProductFilterSimple,
    ProductSortSelector,
    ProductStatus
  },
  data() {
    return {
      productList: [] as api.Product[],
      selectedProducts: [] as api.Product[],
      filterData: {
        name: "",
        brandId: "",
        type: ""
      },
      pagination: {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      queryParams: {
        name: "",
        sorting: "",
        status: 0,
        skipCount: 0,
        maxResultCount: 10, // 同pageSize
        type: "",
        brandId: ""
      },
      loading: false
    };
  },
  watch: {
    pagination: {
      handler(pagination) {
        this.queryParams.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
        this.queryParams.maxResultCount = pagination.pageSize;
      },
      deep: true
    },
    filterData: {
      handler(data) {
        this.queryParams = { ...this.queryParams, ...data };
      },
      deep: true
    },
    "queryParams.sorting": function() {
      this.queryParams = { ...this.queryParams };
    },
    queryParams: {
      deep: true,
      async handler() {
        await this.fetchData();
      }
    }
  },
  async created() {
    this.filterData.type = this.$route.query.type as string;
    this.$store.commit("breadcrumbList", [
      {
        name: "产品回收站",
        path: "recycleBin"
      }
    ]);
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const res = await api.getProductRecycleList(this.queryParams);
      this.loading = false;
      if (!api.error(res)) {
        this.pagination.totalCount = res.data.totalCount;
        this.productList = res.data.items;
      }
    },
    handleSelectionChange(selection: api.Product[]) {
      this.selectedProducts = selection;
    },
    async restoreProduct(products: api.Product[]) {
      const res = await api.restoreProductFromRecycleBin(
        products.map(product => product.productId)
      );
      if (!api.error(res)) {
        this.$message({
          type: "success",
          message: "还原成功"
        });
        await this.fetchData();
      } else {
        this.$message({
          type: "error",
          message: res.error.message
        });
      }
    },
    removeProduct(products: api.Product[]) {
      // 从回收站删除
      this.$confirm("产品将彻底删除，无法恢复", "你确定要删除该产品吗？", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "error"
      }).then(async () => {
        const res = await api.removeProductFromRecycleBin(
          products.map(product => product.productId)
        );
        if (!api.error(res)) {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          await this.fetchData();
        } else {
          this.$message({
            type: "error",
            message: res.error.message
          });
        }
      });
    },
    async restoreProductBatch() {
      await this.restoreProduct(this.selectedProducts);
    },
    removeProductBatch() {
      this.removeProduct(this.selectedProducts);
    },
    handleBatchCommand(command) {
      if (this.selectedProducts.length === 0) {
        this.$message({
          type: "warning",
          message: "未选中任何产品"
        });
        return;
      }
      switch (command) {
        case "remove":
          this.removeProductBatch();
          break;
        case "restore":
          this.restoreProductBatch();
          break;
        default:
      }
    }
  }
});
